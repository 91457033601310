<template>
    <div class="authorization-page">

        <div class="form">
            <router-link :to="{name: 'Home'}">
                <img :src="require('@/assets/img/logo.png')" class="logo" alt="EURECBROKER">
            </router-link>

            <h1>{{ $t("Loading..") }}</h1>


        </div>
        <div class="side">

        </div>
    </div>
</template>

<script>
import {userService} from "@/services/userService";

export default {
    name: "SocialAuthorizationRedirect",
    data() {
        return {

        }
    },
    mounted() {
        this.socialAuth(this.$route.params.network)
    },
    emits: ['hideLoginForm'],
    methods: {
        socialAuth(network) {
            userService.socialAuthRedirect(network)
            .then(response => {
                window.location.replace(response.data.url)
            })
            .catch(error => {

            }).finally(() => this.disabled = false)
        }
    },
}
</script>

<style scoped>

</style>